import { useContext } from 'react';

import { CartContext, CartContextValue } from '@hultafors/solidgear/context';

export function useCart(): CartContextValue {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within CartContext!');
  }
  return context;
}
