import styled, { css } from 'styled-components';

import { buttonReset } from '@hultafors/shared/helpers';

import { colors } from '@hultafors/solidgear/helpers';

const shared = css`
  display: block;
  width: 24px;
  height: 24px;
  position: relative;
  padding: 0;
`;

interface FavoriteProps {
  $filled?: boolean;
}

export const Favorite = styled.div<FavoriteProps>`
  ${shared};
  ${({ $filled }) =>
    $filled
    && css`
      svg {
        stroke: ${colors.solidBlackHover};
        fill: ${colors.solidBlackHover};
      }
    `}
`;
export const Cart = styled.div`
  ${shared};
  &:hover {
    svg path,
    circle {
      fill: ${colors.solidBlackHover};
    }

    svg rect {
      stroke: ${colors.solidBlackHover};
    }
  }
`;
export const CartBadge = styled.div`
  top: -7px;
  right: -8px;
`;

export const NavItemStyled = styled.button`
  ${buttonReset};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
`;

export const NavItemLabel = styled.span`
  text-align: center;
  white-space: nowrap;
  font-size: 10px;
  letter-spacing: 0.5px;
  margin-block-start: 0.25rem;
`;
