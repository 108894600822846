import { useCart, useFavorites } from '@hultafors/solidgear/hooks';

import { Badge } from '../Badge/Badge';

import CartSvg from './cart.svg';
import FavoriteSvg from './favorite.svg';
import {
  Cart,
  CartBadge,
  Favorite,
  NavItemLabel,
  NavItemStyled,
} from './nav-item.styled';

interface NavItemProps {
  isFavorite?: boolean;
  toggle(...args: any[]): void;
  inTopMenu?: boolean;
  label?: string;
  className?: string;
}

export const NavItem: React.FC<NavItemProps> = ({
  isFavorite,
  toggle,
  label = '',
  className,
}) => {
  const { cart } = useCart();
  const { favorites } = useFavorites();
  return (
    <NavItemStyled onClick={toggle} aria-label={label} className={className}>
      {isFavorite
        ? (
          <Favorite $filled={favorites.length > 0}>
            <FavoriteSvg
              focusable={false}
              aria-hidden={true}
              width={24}
              height={24}
            />
          </Favorite>
          )
        : (
          <Cart>
            <CartSvg
              focusable={false}
              aria-hidden={true}
              width={24}
              height={24}
            />
            {cart.length > 0 && (
              <CartBadge as={Badge} tiny round red>
                {cart.length}
              </CartBadge>
            )}
          </Cart>
          )}
      <NavItemLabel>{label}</NavItemLabel>
    </NavItemStyled>
  );
};
